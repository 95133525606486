import { ICellRendererParams } from 'ag-grid-community';
import { Tabs } from 'antd';
import AGGrid from 'components/ReactGrid';
import { legGridConfig } from 'containers/Financial/Invoicing/components/legGridConfig';
import { selectItemExpandDetails } from 'context/financials_invoicing/slice';
import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'twin.macro';
import CostAndChargeGrid from './CostAndChargeGrid';
import { chargeGridOptions } from './CostAndChargeGrid/gridOptions';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  padding: 10px;
  margin-left: 40px;
  .ant-tabs-tab {
    background: #f9fafb !important;
    color: #8d8d8d;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    &:hover {
      color: #001529;
    }
  }
  .ant-tabs-tab-active {
    background: #fff !important;
    .ant-tabs-tab-btn div {
      color: #8d8d8d;
      font-weight: bold;
    }
  }
`;

const StyledTabPane = styled(TabPane)`
  padding-left: 20px;
`;

export default function BookingDetailExpand(
  props: Readonly<ICellRendererParams>,
) {
  const { data } = props;
  const bookingExpandList = useSelector(selectItemExpandDetails);
  const bookingExpandItem = bookingExpandList?.find(
    item => item?.id === data?.id,
  );

  const renderLegs = () => {
    return (
      <StyledTabPane key="legs" tab={<div>Moves</div>}>
        <div id="leg-grid" className="h-[150px] mb-[10px]">
          <AGGrid
            rowData={bookingExpandItem?.bookingLegs}
            gridOptions={legGridConfig}
          />
        </div>
      </StyledTabPane>
    );
  };

  const renderSurcharges = () => {
    return (
      <StyledTabPane key="surcharges" tab={<div>Costs & Charges</div>}>
        <CostAndChargeGrid
          gridConfig={chargeGridOptions}
          surcharges={bookingExpandItem?.bookingBookingChargeMappings}
          cartageCharges={bookingExpandItem?.bookingLines}
          legs={bookingExpandItem?.bookingLegs}
        />
      </StyledTabPane>
    );
  };
  return (
    <StyledTabs type="card" tabBarGutter={0}>
      {renderLegs()}
      {renderSurcharges()}
    </StyledTabs>
  );
}
