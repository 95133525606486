import Field from 'components/Form/Field';
import FormControlledSelect from 'components/common/FormControlledSelect';
import useBookingRate from 'context/financials_rate/hooks';
import React from 'react';

export default function DetailsForm() {
  const { selectedFafGroupNewOrgsList } = useBookingRate();

  return (
    <div className="grid gap-3 grid-cols-12 h-auto px-3 mt-3">
      <Field label="Customers" isRequired span={24}>
        <FormControlledSelect
          name="fafGroupNewOrgOrgIds"
          optionFilterProp="label"
          mode="multiple"
          setDefaultOption={false}
          options={selectedFafGroupNewOrgsList?.map(el => {
            return { value: el.id, label: el.displayName };
          })}
        />
      </Field>
    </div>
  );
}
