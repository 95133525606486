import { ColumnState } from 'ag-grid-community';

export type TSortDetail = {
  field: string;
  colId: string;
  sort: string;
  sortIndex: number;
};

export interface IAgGridOptions {
  onClearSort?: () => void;
}

export type ColumnGroupState = {
  groupId: string;
  open: boolean;
};

// LocalStorage Grid settings  model
export type TdefaultGridSettings = {
  columnState?: ColumnState[];
  columnGroupState?: ColumnGroupState[];
};

export enum TGridColumnType {
  ClickToChangeColumn = 'ClickToChangeColumn',
  EditableColumn = 'EditableColumn',
}
