import { message } from 'antd';
import { WorkspaceQuery } from 'components/Layout/types';
import { TGridColumnType } from 'components/ReactGrid/types';
import { selectQuery, selectWorkspaces } from 'context/workspace/selectors';
import { generalFilterEngines } from 'helpers/filterEngines';
import { sortEngines } from 'helpers/sortEngines';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GridPanel from './GridPanel';
import { StyledForm } from './styles';

type Props = Readonly<{
  gridOptions?: any;
  data?: any;
  flagRows?: any[];
  selectedItems?: any[];
  onGetDataList?: (query: any) => void;
  onRefreshDataList?: () => void;
  onCreateNew?: () => void;
  onDuplicate?: () => void;
  onViewRecord?: (record: any) => void;
  onDelete?: (ids: number[]) => Promise<{ data: any; message: string }>;
}>;

export default function DefaultLayout(props: Props) {
  const workspace = useSelector(selectWorkspaces);
  const [loading, setLoading] = useState(false);

  const workspaceQuery: WorkspaceQuery = useSelector(selectQuery);
  const [mainQuery, setMainQuery] = useState(null);

  // =================== Handlers ===========================//

  const handleInternalFilterChanged = (filterState, sortState) => {
    setLoading(true);
    let externalDateFilter;
    let filterAnd = [];
    const refinedSort = sortEngines(sortState, workspace?.module?.value);
    externalDateFilter = generalFilterEngines(filterState);

    const newQuery = {
      ...mainQuery,

      filters: returnNullIfEmpty({
        ...externalDateFilter,
        ...(filterAnd?.length && {
          and: filterAnd,
        }),
      }),
      ...(refinedSort?.length && {
        order: refinedSort,
      }),
    };
    setMainQuery(newQuery);
  };

  const handleInternalSortChanged = (
    sortState: [{ colId: string; sort: string; sortIndex: number }],
  ) => {
    const refinedSort = sortEngines(sortState, workspace?.module?.value);
    if (refinedSort?.length) {
      setMainQuery(prevState => ({
        ...prevState,
        order: refinedSort,
      }));
    }
  };

  const handleGridPagination = () => {
    try {
      if (
        props.data?.pageInfo?.hasNextPage &&
        props.data?.pageInfo?.endCursor &&
        !loading
      ) {
        setLoading(true);
        const cursor = props.data.pageInfo.endCursor;
        props.onGetDataList({ ...mainQuery, cursor });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowDoubleClick = event => {
    const record = event.data;
    const colType = event.column.colDef.type;
    const suppressRowDoubleClick =
      colType === TGridColumnType.ClickToChangeColumn ||
      colType === TGridColumnType.EditableColumn;
    if (!suppressRowDoubleClick) {
      props.onViewRecord?.(record);
    }
  };

  const handleDeleteRecord = async (ids: number[]) => {
    const result = await props?.onDelete(ids);
    if (result?.data) {
      message.success(result.message);
      props?.onGetDataList(mainQuery || workspaceQuery);
    } else {
      message.error(result.message);
    }
  };

  const getInitialData = () => {
    const newInitWorkspaceQuery = {
      ...workspaceQuery,
      filters: workspaceQuery?.filters ? workspaceQuery.filters : null,
    };
    const initQuery = newInitWorkspaceQuery || {
      filters: null,
      cursor: null,
      order: null,
    };
    setMainQuery(initQuery);
  };

  const returnNullIfEmpty = newFilter => {
    return Object.keys(newFilter).length === 0 ? null : newFilter;
  };

  // =================== UseEffects =========================== //

  useEffect(() => {
    if (mainQuery) {
      setLoading(true);
      props?.onGetDataList(mainQuery);
    }
    return () => {
      if (props?.onRefreshDataList) {
        props?.onRefreshDataList();
      }
    };
  }, [mainQuery]);

  useEffect(() => {
    setLoading(props.data?.loading);
  }, [props.data]);

  // =================== Render components =========================== //

  if (workspace) {
    return (
      <StyledForm>
        <div className="h-full">
          <GridPanel
            gridOptions={props?.gridOptions}
            module={workspace?.module?.value}
            loading={loading}
            data={props.data}
            flagRows={props.flagRows}
            selectedItems={props.selectedItems}
            toolbar={workspace?.toolbar}
            screenViews={workspace?.screenViews}
            onGridPagination={handleGridPagination}
            onGridRowDoubleClick={handleRowDoubleClick}
            onViewRecord={props.onViewRecord}
            onInternalFilterChanged={handleInternalFilterChanged}
            onInternalSortChanged={handleInternalSortChanged}
            onCreateNew={props.onCreateNew}
            onDuplicate={props.onDuplicate}
            onDelete={handleDeleteRecord}
            onGetInitialData={getInitialData}
          />
        </div>
      </StyledForm>
    );
  }
  return null;
}
