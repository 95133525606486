// import useUsers from 'app/context/securityUsers/hooks';
import { Flex as AntFlex } from 'antd';
import React from 'react';
import tw, { styled } from 'twin.macro';
import DispatchActions from './DispatchActions';
import Filters from './Filters';
import GeneralActions from './GeneralActions';

type Props = Readonly<{
  toolbar?: any;
  className?: string;
  module?: string;
  selectedItems?: any[];
  isSubscriber?: boolean;
  onApplyFilter?: (query: any) => void;
  onClearFilter?: () => void;
  onCreateNew?: () => void;
  onDuplicate?: () => void;
  onDisable?: () => void;
  onMultipleDelete?: () => void;
  onExcelExport?: () => void;
  onCSVExport?: () => void;
  onUprater?: () => void;
}>;

const StyledForm = styled.div`
  ${tw`relative p-1`}
  background-color: ${p => p.theme.toolbarBackgroundColor};
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
`;

const Flex = styled(AntFlex)`
  background-color: ${p => p.theme.toolbarBackgroundColor};
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  padding: 0.25rem;
`;

function TopWidgets(props: Props) {
  // = ============================= init ===========================//
  const {
    toolbar,
    className,
    module,
    selectedItems,
    isSubscriber,
    onApplyFilter,
    onClearFilter,
    onCSVExport,
    onExcelExport,
    onCreateNew,
    onMultipleDelete,
    onDisable,
    onDuplicate,
    onUprater,
  } = props;

  // = ============================= render components ===========================//

  const renderToolbar = () => {
    return (
      <Flex align="center" wrap="wrap">
        {toolbar?.actions?.length ? (
          <GeneralActions
            module={module}
            actions={toolbar?.actions}
            onExcelExport={onExcelExport}
            onCSVExport={onCSVExport}
            onCreateNew={onCreateNew}
            onMultipleDelete={onMultipleDelete}
            onDisable={onDisable}
            onDuplicate={onDuplicate}
            selectedItems={selectedItems}
          />
        ) : null}

        <Filters
          module={module}
          fields={toolbar?.filters}
          onApplyFilter={onApplyFilter}
          onClearFilter={onClearFilter}
        />
        {toolbar?.jobs?.length && (
          <DispatchActions
            jobs={toolbar?.jobs}
            onUprater={onUprater}
            module={module}
            selectedItems={selectedItems}
            isSubscriber={isSubscriber}
          />
        )}
      </Flex>
    );
  };

  // = ============================= main render ===========================//

  return <StyledForm className={className}>{renderToolbar()}</StyledForm>;
}

export default TopWidgets;
