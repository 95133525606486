export const toPrecision = (s: string | number, precision: number = 2) =>
  (Number(s) && Number(s).toFixed(precision)) || '0.00';

export const dateToISOString = (date: Date | undefined | null) => {
  return JSON.parse(JSON.stringify(date));
};

/**
 * Usage:
 * spinalCase('ExtraCharge')
 *
 * LOG: 'Extra Charge'
 */
export function spinalCaseToTitleCase(str: string): string {
  const toUpper = str.charAt(0).toUpperCase() + str.slice(1);
  const lowercase = toUpper.trim();
  const regEx = /\W+|(?=[A-Z])|_/g;

  return lowercase.split(regEx).join(' ');
}

export const bookingPlaceColor = bookingPlaceType => {
  switch (bookingPlaceType) {
    case 2:
      return '#6FCD12';
    case 3:
      return '#FF7A00';
    case 4:
      return '#10CFA1';
    default:
      return '#1890ff';
  }
};

export const stringLiteral = (
  obj: Record<string, any>,
  fields?: string[],
): string => {
  const keys = fields || Object.keys(obj);
  const valuesArray = keys
    .map(field => obj[field])
    .filter(value => Boolean(value));
  return valuesArray.length > 0 ? valuesArray.join(', ') : '';
};
