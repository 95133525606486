import {
  CellClickedEvent,
  ColumnApi,
  ColumnVisibleEvent,
  CsvExportParams,
  ExcelExportParams,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  MenuItemDef,
} from 'ag-grid-community';
import { message } from 'antd';
import AGGrid from 'components/ReactGrid';
import { ModuleTypes } from 'components/config/types/modules';
import useAuthentication from 'context/security_authentication/hook';
import useScreenViews from 'context/setting_screenViews/hooks';
import { debounce, merge } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import BottomTabView from '../BottomTabView';
import TopWidgets from '../TopWidgets';
import { defaultGridOptions } from './settings';
import { commonColumns } from './types';
import { OrganizationType } from 'components/config/types/common';

type Props = Readonly<{
  data?: any;
  flagRows?: any[];
  selectedItems?: any[];
  module?: string;

  toolbar?: any;
  screenViews?: any;
  gridOptions?: GridOptions;
  loading: boolean;
  disabled?: boolean;
  paginaton?: boolean;
  pageSize?: number;

  onApplyFilter?: (query: any) => void;
  onClearFilter?: () => void;
  onCreateNew?: () => void;
  onDuplicate?: () => void;
  onDelete?: (id: number[]) => void;
  onCancel?: (id: number) => void;
  onDisable?: () => void;

  onExcelExport?: () => void;
  onCSVExport?: () => void;
  onManifest?: () => void;
  onUprater?: () => void;
  onGridPagination?: () => void;
  onInternalFilterChanged?: (filterState: any, sortState: any) => void;
  onInternalSortChanged?: (sortState: any) => void;
  onGridRowDoubleClick: (event: any) => void;
  onCellClicked?: (event: CellClickedEvent) => void;
  onViewRecord?: (data: any) => void;
  onGetContextMenu?: (
    params: GetContextMenuItemsParams,
  ) => (string | MenuItemDef)[];
  onGetInitialData?: () => void;
}>;

const columnDefsPath = 'columnDefs';
const filterPath = 'filterModel';
const sortPath = 'sortModel';
const pinnedOrder: Record<string, number> = {
  left: -1,
  null: 0,
  right: 1,
};

function GridPanel(props: Props) {
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const [gridData, setGridData] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>(null);
  const { currentView, updateTab, updatingTab } = useScreenViews();
  const { isAllow, currentAccount } = useAuthentication();
  const previouscolumnState = useRef(null);
  const getRowId = params => params.data.id;
  const displayedRowCount = gridApi?.getDisplayedRowCount();

  const onGridReady = params => {
    const { api, columnApi } = params;
    if (props.module === ModuleTypes.Group.value) {
      api.sizeColumnsToFit(true);
    }
    setGridColumnApi(columnApi);
    setGridApi(api);
    previouscolumnState.current = gridColumnApi?.getColumnState();
  };

  const gridOptions = useMemo(() => {
    let columnDefs = props.gridOptions?.columnDefs || [];
    columnDefs = [commonColumns.chbxCol, ...columnDefs, commonColumns.viewCol];
    const allowDelete = isAllow('delete', props.module);
    if (allowDelete) {
      columnDefs = [...columnDefs, commonColumns.delCol];
    }
    const customOptions = merge({ ...defaultGridOptions }, props.gridOptions);

    return {
      ...customOptions,
      columnDefs,
      onCellDoubleClicked: event => {
        props.onGridRowDoubleClick(event);
      },
    };
  }, [props.module]);

  const handleSavedGridFilterState = () => {
    // compare current filterModel with the filterState if they are the same then do nothing
    const filterState = gridApi?.getFilterModel();
    const activeTabId = currentView?.activeTab;
    let currentFilterState = updatingTab?.filterModel;
    if (!updatingTab) {
      const currentViewData = currentView?.tabs?.find(
        item => item?.tabId === activeTabId,
      );
      currentFilterState = currentViewData?.filterModel;
    }
    if (JSON.stringify(filterState) === JSON.stringify(currentFilterState)) {
      return;
    }
    gridApi?.deselectAll(); // reset selected rows
    const colState = gridColumnApi.getColumnState();
    const sortState = handleGetSortState(colState);
    handleUpdateTab();

    if (props?.onInternalFilterChanged) {
      props.onInternalFilterChanged(filterState, sortState);
    }
  };

  const handleSavedGridSortState = () => {
    if (!gridColumnApi) return;
    if (!gridData) return;
    const columnState = gridColumnApi?.getColumnState();
    const sortState = handleGetSortState(columnState);
    const currentSortState = currentView?.tabs?.find(
      item => item.tabId === currentView?.activeTab,
    )?.sortModel;
    if (JSON.stringify(sortState) === JSON.stringify(currentSortState)) return;
    if (props?.onInternalSortChanged) {
      props.onInternalSortChanged(sortState);
    }
    handleUpdateTab();
    gridApi?.deselectAll(); // reset selected rows
  };

  const handleGetSortState = colState => {
    return colState
      ?.filter(s => {
        return s.sort != null;
      })
      .map(s => {
        return { colId: s?.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
  };

  const handleSaveGridColumnState = () => {
    if (!gridColumnApi || !gridData) return;

    const columnState = gridColumnApi.getColumnState();
    const maxPinnedColumns = { left: 9, right: 6 };

    // Function to enforce maximum number of pinned columns on either side
    const enforceMaxPinnedColumns = side => {
      const columns = columnState.filter(el => el.pinned === side);
      const maxPinned = maxPinnedColumns[side];
      const errorMessage = `You can pin up to ${
        side === 'right'
          ? maxPinnedColumns[side] - 2
          : maxPinnedColumns[side] - 1
      } columns on the ${side}.`;
      // Adjust column state if the number of pinned columns exceeds the maximum allowed
      if (columns.length > maxPinned) {
        const previousColumns = previouscolumnState.current?.filter(
          el => el.pinned === side,
        );
        // Revert to previous state or adjust current state based on previous pinned columns
        if (previousColumns.length > maxPinned) {
          let count = 0;
          const filteredColumnState = columnState.map(el => {
            // Logic to adjust pinning based on max allowed
            if (el.pinned === side && count < maxPinned) {
              count++;
              return el;
            }
            return { ...el, pinned: null };
          });
          gridColumnApi.applyColumnState({ state: filteredColumnState });
        } else {
          gridColumnApi.applyColumnState({
            state: previouscolumnState.current,
          });
        }
        message.error(errorMessage);
        return true;
      }
      return false;
    };
    // Check and enforce max pinned columns for both sides
    const leftExceeded = enforceMaxPinnedColumns('left');
    const rightExceeded = enforceMaxPinnedColumns('right');
    // Update tab only if the pinned columns do not exceed the maximum allowed
    if (!leftExceeded && !rightExceeded) {
      handleUpdateTab();
    }
  };

  const handleColumnVisible = (event: ColumnVisibleEvent) => {
    const { columnApi, column, columns, visible } = event;
    if (columns?.length > 1) return;
    const columnId = column.getColId();
    if (visible) {
      const allColumns = columnApi.getAllGridColumns();
      const firstRightPinnedColumnIndex = allColumns.findIndex(
        col => col.getPinned() === 'right',
      );
      const lastColumnIndex =
        firstRightPinnedColumnIndex === -1
          ? allColumns.length
          : firstRightPinnedColumnIndex;
      columnApi.moveColumn(column, lastColumnIndex - 1);
      handleUpdateTab();
    } else {
      columnApi.applyColumnState({
        state: [
          {
            colId: columnId,
            pinned: null,
            sort: null,
            sortIndex: null,
          },
        ],
      });
      gridApi.destroyFilter(columnId);
    }
  };

  const handleUpdateTab = () => {
    const columnState = gridColumnApi?.getColumnState();
    const filterState = gridApi?.getFilterModel();
    const sortState = handleGetSortState(columnState);
    const tabSettings = {
      [columnDefsPath]: columnState,
      [filterPath]: filterState,
      [sortPath]: sortState,
    };
    updateTab(tabSettings);
    previouscolumnState.current = gridColumnApi?.getColumnState();
  };

  const handleCSVExport = () => {
    if (gridApi) {
      const params: CsvExportParams = {
        fileName: 'Exported_Booking_Data.csv',
        onlySelected: true,
      };
      gridApi.exportDataAsCsv(params);
    }
  };

  const handleExcelExport = () => {
    if (gridApi) {
      const params: ExcelExportParams = {
        fileName: 'Exported_Booking_Data.xlsx',
        onlySelected: true,
      };
      gridApi.exportDataAsExcel(params);
    }
  };

  const handleViewRecord = data => {
    props.onViewRecord(data);
  };

  const handleDeleteRecord = event => {
    props.onDelete([event.data.id]);
  };

  const handleMultipleDelete = () => {
    if (props?.selectedItems?.length) {
      const ids = props.selectedItems.map(item => item.id);
      props.onDelete(ids);
    }
  };

  const handlCancelRecord = event => {
    props.onCancel(event.data.id);
  };

  const handleClearFilter = () => {
    gridApi.setFilterModel(null);
    handleUpdateTab();
    props.onClearFilter();
    message.success('Filters have been cleared');
  };

  const handlePagination = () => {
    const lastDisplayedRow = gridApi?.getLastDisplayedRow() + 1;

    if (
      lastDisplayedRow === props?.data?.nodes?.length &&
      props?.data?.nodes?.length !== 0 &&
      props?.onGridPagination
    ) {
      props.onGridPagination();
    }
  };

  useEffect(() => {
    if (props?.data?.nodes) {
      gridApi?.deselectAll();
      const dataItems = props.data?.nodes?.map(row => {
        return {
          ...row,
        };
      });
      setGridData(dataItems);
    }
  }, [props?.data]);

  useEffect(() => {
    // get initial data
    if (currentView?.activeTab) {
      // when registry is loaded
      if (!gridColumnApi) return;
      const activeTabId = currentView?.activeTab;
      const currentViewData = currentView?.tabs?.find(
        item => item.tabId === activeTabId,
      );
      const currentColumnDefs = currentViewData?.columnDefs;
      const currentFilters = currentViewData?.filterModel;
      gridApi?.setFilterModel(currentFilters);
      if (currentColumnDefs && gridColumnApi) {
        const columnDefs = [...currentColumnDefs];
        const reOrderedColumns = columnDefs.sort((a, b) => {
          return pinnedOrder[a?.pinned] - pinnedOrder[b?.pinned];
        });
        gridColumnApi?.applyColumnState({
          state: reOrderedColumns,
          applyOrder: true,
        });
      }

      const colState = gridColumnApi.getColumnState();
      const filterState = gridApi?.getFilterModel();
      const sortState = handleGetSortState(colState);
      if (props?.onInternalFilterChanged) {
        props.onInternalFilterChanged(filterState, sortState);
      }
    } else {
      // when registry is not loaded
      props?.onGetInitialData();
    }
  }, [currentView?.activeTab, props.module]);

  return (
    <div>
      {props.toolbar ? (
        <TopWidgets
          module={props.module}
          toolbar={props.toolbar}
          selectedItems={props.selectedItems}
          onApplyFilter={props.onApplyFilter}
          onClearFilter={handleClearFilter}
          onExcelExport={handleExcelExport}
          onCSVExport={handleCSVExport}
          onCreateNew={props.onCreateNew}
          onDuplicate={props.onDuplicate}
          onDisable={props.onDisable}
          onMultipleDelete={handleMultipleDelete}
          isSubscriber={
            currentAccount?.organizationOrganizationMapping
              ?.organizationTypeId === OrganizationType.Subscriber
          }
        />
      ) : null}
      <BottomTabView
        module={props.module}
        screenViews={props.screenViews}
        gridOptions={props.gridOptions}
      >
        <div
          className="list-container"
          style={{
            height: `calc(100vh - 205px)`,
          }}
        >
          <AGGrid
            {...gridOptions}
            loading={props.loading}
            error={props.data?.error}
            getRowId={getRowId}
            context={{
              handleViewRecord,
              handleDeleteRecord,
              handlCancelRecord,
              ...props.gridOptions?.context,
            }}
            flagRows={props.flagRows}
            selectedItems={props.selectedItems}
            onGridReady={onGridReady}
            rowData={gridData}
            gridColumnApi={gridColumnApi}
            gridApi={gridApi}
            onBodyScrollEnd={handlePagination}
            onFilterChanged={debounce(() => handleSavedGridFilterState(), 500)}
            onColumnRowGroupChanged={debounce(
              () => handleSavedGridFilterState(),
              500,
            )}
            onSortChanged={handleSavedGridSortState}
            onColumnVisible={handleColumnVisible}
            onColumnPinned={debounce(() => handleSaveGridColumnState(), 500)}
            onColumnResized={debounce(() => handleSaveGridColumnState(), 500)}
            onColumnMoved={debounce(() => handleSaveGridColumnState(), 500)}
            onColumnValueChanged={debounce(
              () => handleSaveGridColumnState(),
              500,
            )}
            onDisplayedColumnsChanged={debounce(
              () => handleSaveGridColumnState(),
              500,
            )}
          />
        </div>
        <div className="p-3">
          <span style={{ color: '#A6ABB5', marginRight: '4px' }}>
            Total Records
          </span>
          <span style={{ marginRight: '6px' }}>
            {props.data?.totalCount || displayedRowCount}
          </span>
          {props.selectedItems?.length > 0 ? (
            <>
              <span style={{ color: '#A6ABB5', marginRight: '4px' }}>
                Selected
              </span>
              <span> {props.selectedItems?.length}</span>
            </>
          ) : null}
        </div>
      </BottomTabView>
    </div>
  );
}

export default GridPanel;
