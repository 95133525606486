import { ColDef } from 'ag-grid-community';

export const commonColumns: { [key: string]: ColDef } = {
  chbxCol: {
    headerName: 'Select Box',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    maxWidth: 30,
    cellClass: 'ml-1 rounded-sm',
    pinned: 'left',
    filter: false,
    cellStyle: {
      textAlign: 'center !important',
    },
  },
  viewCol: {
    headerName: 'View',
    pinned: 'right',
    filter: false,
    maxWidth: 40,
    colId: 'view',
    cellRenderer: 'ViewRow',
    suppressMovable: true,
    lockPinned: true,
    sortable: false,
  },
  delCol: {
    headerName: 'Delete',
    pinned: 'right',
    filter: false,
    maxWidth: 40,
    colId: 'delete',
    cellRenderer: 'DeleteRow',
    suppressMovable: true,
    lockPinned: true,
    sortable: false,
  },
};
