import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';
import { Button, Popconfirm } from 'antd';
import React from 'react';
import tw, { styled } from 'twin.macro';

export interface Props {
  disabled?: boolean;
  context?: any;
}

const StyledWrapper = styled.div`
  ${tw`flex w-full relative text-center items-center justify-center`}
  .anticon {
    vertical-align: baseline;
  }
`;

export default (props: Props & ICellRendererParams) => {
  const text = 'Are you sure you want to delete these items?';
  const confirm = () => {
    props.context.handleDeleteRecord(props);
  };

  return (
    <StyledWrapper>
      <Popconfirm
        className="flex bg-transparent items-center"
        placement="leftBottom"
        title={text}
        onConfirm={confirm}
        okText="Yes"
        cancelText="No"
        disabled={props.disabled}
      >
        {!props.node.rowPinned ? (
          <Button
            size="small"
            disabled={props.disabled}
            className={`flex justify-center ${
              props.disabled ? 'opacity-20' : 'opacity-100'
            }`}
            icon={
              <FontAwesomeIcon
                icon={faTrash}
                style={{ color: 'red', cursor: 'pointer' }}
              />
            }
          />
        ) : (
          <span />
        )}
      </Popconfirm>
    </StyledWrapper>
  );
};
