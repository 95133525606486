import { faClose, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  message,
} from 'antd';
import {
  GetRateCommodityQuery,
  RateCommodityFilterInput,
  RateCommoditySortInput,
  useGetRateCommodityLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { containerSizes } from 'components/config/types/booking';
import useBookingRate from 'context/financials_rate/hooks';
import useGenericCodeLists from 'context/genericCodeLists/hooks';
import useAuthentication from 'context/security_authentication/hook';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

type Props = Readonly<{
  control: any;
  name?: string;
}>;

interface DropdownMenuProps {
  handleShowModal: () => void;
}

function FormControlledCommoditySelect(props: Props) {
  const { control, name } = props;
  const [rateCommodityForm] = Form.useForm();

  const [options, setOptions] = useState([]);
  const [rateCommodityList, setRateCommodityList] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const { genericCodeListData } = useGenericCodeLists();
  const { currentAccount } = useAuthentication();
  const { createRateCommodity } = useBookingRate();

  const [containerSizeVisible, setContainerSizeVisible] = useState(false);

  // commodity modal
  const renderCommodityModal = () => {
    const bookingTypeOptions = genericCodeListData?.BookingType?.map(el => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });

    const handleValueChange = changedValues => {
      const containerId = bookingTypeOptions.find(
        el => el.label.toLowerCase() === 'container',
      )?.value;
      if (changedValues?.bookingTypeId) {
        setContainerSizeVisible(changedValues?.bookingTypeId === containerId);
      }
    };

    const handleSubmit = async () => {
      setConfirmLoading(true);
      const fieldValues = rateCommodityForm.getFieldsValue();
      const payload = {
        ...fieldValues,
        subscriberOrganizationId:
          currentAccount?.organizationOrganizationMapping?.childOrganizationId,
      };
      const qry = await createRateCommodity(payload);
      const {
        data: { addRateCommodity: resultQry },
      } = qry;

      if (resultQry?.rateCommodity) {
        const newList = [resultQry?.rateCommodity, ...rateCommodityList];
        refineOptions(newList);
        rateCommodityForm.resetFields(); // reset fields on rete commodify modal
        setOpen(false);
        message.success('Create rate commodity successfully');
        setSelectedValue(resultQry?.rateCommodity?.id); // set the new rate commodity to the commodity field
      } else {
        message.error('Cannot create rate commodity');
      }
      setConfirmLoading(false);
    };

    return (
      <Modal
        title="Create Rate Commodity"
        open={open}
        onOk={handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Save"
      >
        <Form
          layout="vertical"
          form={rateCommodityForm}
          onValuesChange={handleValueChange}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Booking Type"
                name="bookingTypeId"
                initialValue={bookingTypeOptions[0].value}
                required
              >
                <Select options={bookingTypeOptions} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Name" name="name" required>
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Description" name="description">
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            {containerSizeVisible ? (
              <Col span={12}>
                <Form.Item
                  label="Container Size"
                  name="containerSizeId"
                  initialValue={containerSizes[0].value}
                  required
                >
                  <Select options={containerSizes} />
                </Form.Item>
              </Col>
            ) : null}
          </Row>
        </Form>
      </Modal>
    );
  };
  // commodity modal

  const refineOptions = dataList => {
    setRateCommodityList(dataList);
    const listOptions = dataList?.map(dataItem => {
      const bookingType = genericCodeListData?.BookingType?.find(
        type => type?.id === dataItem?.bookingTypeId,
      )?.name;
      if (dataItem?.id) {
        return {
          value: dataItem?.id,
          label: (
            <p className="capitalize">
              {dataItem?.name}
              <i>{bookingType ? ` - ${bookingType}` : null}</i>
            </p>
          ),
        };
      }
      return undefined;
    });
    setOptions(listOptions);
  };

  const [getRateCommodityQuery] = useGetRateCommodityLazyQuery({
    onCompleted: response => {
      if (response?.rateCommodities?.nodes) {
        refineOptions(response?.rateCommodities?.nodes);
      }
    },
  });

  const getRateCommodity = variables => {
    getRateCommodityQuery({
      variables,
    });
  };

  const handleShowModal = () => {
    rateCommodityForm.resetFields();
    setContainerSizeVisible(false);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const _debouncedSearch = value => {
    const variables = {
      filters: {
        subscriberOrganizationId: {
          eq:
            currentAccount?.organizationOrganizationMapping
              ?.childOrganizationId,
        },
        isDeleted: { neq: true },
        name: { contains: value },
      } as RateCommodityFilterInput,
      cursor: null,
      order: { name: 'ASC' } as RateCommoditySortInput,
    } as GetRateCommodityQuery;
    getRateCommodity(variables);
  };

  useEffect(() => {
    const variables = {
      filters: {
        subscriberOrganizationId: {
          eq:
            currentAccount?.organizationOrganizationMapping
              ?.childOrganizationId,
        },
        isDeleted: { neq: true },
      } as RateCommodityFilterInput,
      cursor: null,
      order: { name: 'ASC' } as RateCommoditySortInput,
    } as GetRateCommodityQuery;
    getRateCommodity(variables);
  }, [module]);

  const DropdownMenu: React.FC<DropdownMenuProps> = ({ handleShowModal }) => (
    <>
      <Space align="center" style={{ padding: '0 8px 4px' }}>
        <Typography.Link
          onClick={handleShowModal}
          style={{ whiteSpace: 'nowrap' }}
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{ verticalAlign: 'unset' }}
          />
          Add New Commodity
        </Typography.Link>
      </Space>
      <Divider style={{ margin: '8px 0' }} />
    </>
  );

  return (
    <>
      {renderCommodityModal()}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onBlur, onChange, ref } }) => (
          <Select
            showSearch
            allowClear
            placeholder="Search Commodity"
            ref={ref}
            value={selectedValue || value}
            onBlur={onBlur}
            onSelect={(_value, option) => {
              onChange(option?.value);
              setSelectedValue(option?.value);
            }}
            popupClassName="certain-category-search-dropdown"
            clearIcon={<FontAwesomeIcon icon={faClose} />}
            options={options}
            filterOption={(input, option) =>
              option?.label
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onSearch={debounce(_debouncedSearch, 400)}
            dropdownRender={menu => (
              <>
                <DropdownMenu handleShowModal={handleShowModal} />
                {menu}
              </>
            )}
          />
        )}
      />
    </>
  );
}

export default FormControlledCommoditySelect;
