import {
  useCreateRegistryMutation,
  useGetRegistriesLazyQuery,
  useModifyRegistryMutation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectActiveTab,
  selectCurrentView,
  selectDefaultViews,
  selectPending,
  selectScreenViewRegistry,
  selectScreenViewSettings,
  selectUpdatingColumnDefs,
  selectUpdatingTab,
  sliceKey,
} from './slice';

export default function useScreenViews() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const defaultViews = useSelector(selectDefaultViews);
  const screenViewRegistry = useSelector(selectScreenViewRegistry);
  const screenViewSettings = useSelector(selectScreenViewSettings);
  const currentView = useSelector(selectCurrentView);
  const activeTab = useSelector(selectActiveTab);
  const updatingTab = useSelector(selectUpdatingTab);
  const updatingColumnDefs = useSelector(selectUpdatingColumnDefs);
  const pendingSreenView = useSelector(selectPending);

  const refreshScreenViews = () => {
    dispatch(
      actions.setScreenViewRegistry({
        screenViewRegistry: null,
        screenViewSettings: null,
        currentView: null,
      }),
    );
  };

  const [
    getScreenViewRegistryQuery,
    { loading: pendingScreenViewList },
  ] = useGetRegistriesLazyQuery({
    onCompleted: response => {
      if (response?.registries?.nodes) {
        const nodes = response?.registries?.nodes;
        const screenViewSettingObj =
          (nodes?.length > 0 && JSON.parse(nodes[0]?.value)) || {};
        const activeViewId = screenViewSettingObj?.activeView;
        const activeView = screenViewSettingObj?.screenViews?.find(
          item => item.viewId === activeViewId,
        );

        dispatch(
          actions.setScreenViewRegistry({
            screenViewRegistry: nodes[0],
            screenViewSettings: screenViewSettingObj,
            currentView: activeView,
            pendingScreenViewList,
          }),
        );
      }
    },
  });

  const getScreenViewRegistry = ({ filters, cursor, order }) => {
    getScreenViewRegistryQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const [getDefaultViewQuery] = useGetRegistriesLazyQuery({
    onCompleted: response => {
      if (response?.registries?.nodes) {
        const nodes = response?.registries?.nodes;

        const defaultViewList = nodes?.map(item => {
          const screenViewObj = JSON.parse(item?.value);
          return screenViewObj?.screenViews?.map(view => view);
        });

        dispatch(
          actions.setDefaultViews({
            defaultViews: defaultViewList.flat(),
          }),
        );
      }
    },
  });

  const getDefaultViews = ({ filters, cursor, order }) => {
    getDefaultViewQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const [
    createScreenViewRegistryMutation,
    { loading: pendingCreateScreenView },
  ] = useCreateRegistryMutation({
    onCompleted: response => {
      if (response?.addRegistry) {
        const newScreenViewValue = JSON.parse(response?.addRegistry?.value);
        dispatch(
          actions.setScreenViewRegistry({
            screenViewRegistry: response?.addRegistry,
            screenViewSettings: newScreenViewValue,
            currentView: newScreenViewValue?.screenViews[0],
          }),
        );
      }

      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createScreenViewRegistry = input => {
    return createScreenViewRegistryMutation({
      variables: {
        input,
      },
    });
  };

  const [
    modifyScreenViewMutation,
    { loading: pendingModifyScreenView },
  ] = useModifyRegistryMutation({
    onCompleted: response => {
      const registry = response?.modifyRegistry;
      if (registry) {
        updateScreenViewRegistry(registry);
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyScreenViewRegistry = (updateView, updatingTab) => {
    let updatedView = null;
    if (updatingTab) {
      const updatedScreenViews = updateView?.screenViews.map(view => {
        let tabs = view.tabs;
        if (view.viewId === currentView.viewId) {
          tabs = view?.tabs.map(tab => {
            if (tab.tabId === updatingTab.tabId) {
              return {
                ...tab,
                columnDefs: updatingTab.columnDefs,
                filterModel: updatingTab.filterModel,
                sortModel: updatingTab.sortModel,
                columnGroupState: updatingTab.columnGroupState,
              };
            }
            return tab;
          });
        }
        return { ...view, tabs };
      });
      updatedView = { ...updateView, screenViews: updatedScreenViews };
    }
    const updateScreenSettings = {
      ...screenViewRegistry,
      value: JSON.stringify(updatedView || updateView),
    };
    delete updateScreenSettings['__typename'];
    return modifyScreenViewMutation({
      variables: {
        input: updateScreenSettings,
      },
    });
  };

  const [
    modifyScreenViewMutationWithoutUpdateRedux,
  ] = useModifyRegistryMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyScreenViewRegistryWithoutUpdatingRedux = updateView => {
    const updateScreenSettings = {
      ...screenViewRegistry,
      value: JSON.stringify(updateView),
    };
    delete updateScreenSettings['__typename'];
    return modifyScreenViewMutationWithoutUpdateRedux({
      variables: {
        input: updateScreenSettings,
      },
    });
  };

  const setCurrentView = view => {
    dispatch(
      actions.updateView({
        screenViewSettings: {
          ...screenViewSettings,
          activeView: view?.viewId,
        },
        currentView: view,
      }),
    );
  };

  const deleteView = viewId => {
    const oldViewList = [...screenViewSettings.screenViews];
    const newViewList = oldViewList?.filter(view => view.viewId !== viewId);
    const newScreenViewSetting = {
      ...screenViewSettings,
      activeView: newViewList[0]?.viewId || null,
      screenViews: newViewList,
    };

    return modifyScreenViewRegistry(newScreenViewSetting, updatingTab).then(
      res => {
        const registry = res?.data?.modifyRegistry;
        if (registry) {
          updateScreenViewRegistry(registry);
          return {
            data: res.data.modifyRegistry,
            message: 'Your views have been save successfully!',
          };
        }
        return { message: 'Cannot save your view, something went wrong!' };
      },
    );
  };

  const deleteViewTab = tabId => {
    if (currentView?.tabs.length <= 1) {
      return {
        data: null,
        message: 'Cannot delete the last tab!',
      };
    }
    const filteredTabs = currentView?.tabs?.filter(tab => tab.tabId !== tabId);
    const newView = {
      ...currentView,
      activeTab:
        currentView?.activeTab === tabId
          ? filteredTabs[0]?.tabId
          : currentView?.activeTab,
      tabs: filteredTabs,
    };
    const newScreenViews = screenViewSettings?.screenViews?.map(item =>
      item.viewId === currentView?.viewId ? newView : item,
    );
    const newScreenViewSetting = {
      ...screenViewSettings,
      screenViews: newScreenViews,
    };

    return modifyScreenViewRegistry(newScreenViewSetting, updatingTab).then(
      res => {
        const registry = res?.data?.modifyRegistry;
        if (registry) {
          updateScreenViewRegistry(registry);
          return {
            data: res.data.modifyRegistry,
            message: 'Your views have been save successfully!',
          };
        }
        return {
          data: null,
          message: 'Cannot save your view, something went wrong!',
        };
      },
    );
  };

  const updateTab = tabValue => {
    if (screenViewSettings && currentView?.tabs) {
      const activeTabId = currentView?.activeTab;
      const activeTab = currentView?.tabs?.find(
        tab => tab.tabId === activeTabId,
      );
      const updatingTab = {
        ...activeTab,
        ...tabValue,
      };

      dispatch(
        actions.updateTab({
          updatingTab,
        }),
      );
    }
  };

  const updateScreenViewRegistry = registry => {
    if (registry) {
      const screenViewSettingObj = JSON.parse(registry?.value) || {};
      const activeViewId = screenViewSettingObj?.activeView;
      const activeView = screenViewSettingObj?.screenViews?.find(
        item => item.viewId === activeViewId,
      );
      dispatch(
        actions.updateScreenViewRegistry({
          screenViewRegistry: registry,
          screenViewSettings: screenViewSettingObj,
          currentView: activeView,
          pendingScreenViewList,
          updatingTab: null,
        }),
      );
    }
  };

  const resetUpdatingTab = () => {
    dispatch(actions.resetUpdatingTab());
  };

  return {
    getDefaultViews,
    getScreenViewRegistry,
    createScreenViewRegistry,
    modifyScreenViewRegistry,
    modifyScreenViewRegistryWithoutUpdatingRedux,
    setCurrentView,
    deleteView,
    refreshScreenViews,
    updateTab,
    deleteViewTab,
    resetUpdatingTab,
    defaultViews,
    screenViewSettings,
    pendingSreenView,
    pendingModifyScreenView,
    pendingCreateScreenView,
    updatingColumnDefs,
    currentView,
    activeTab,
    updatingTab,
  };
}
