import { ICellRendererParams } from 'ag-grid-community';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { styled } from 'twin.macro';

const StyledTimePicker = styled(TimePicker)`
  .ant-picker-input > input {
    width: 36px;
  }
`;

export default function TimePickerCell(props: Readonly<ICellRendererParams>) {
  // eslint-disable-next-line react/prop-types

  const [timeValue, setTimeValue] = useState(null);

  const onTimeChange = date => {
    const newDate = dayjs(date);
    props.setValue(newDate);
  };

  useEffect(() => {
    const timeFormatted = dayjs(props.value);
    setTimeValue(timeFormatted);
  }, [props.value]);

  return (
    <StyledTimePicker
      bordered={false}
      size="small"
      format="HH:mm"
      value={timeValue}
      defaultValue={dayjs(timeValue).isValid() ? dayjs(timeValue) : null}
      style={{
        fontSize: '10px',
      }}
      onChange={date => onTimeChange(date)}
    />
  );
}
