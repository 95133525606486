import { GridOptions, SelectionChangedEvent } from 'ag-grid-community';
import {
  Organization,
  OrganizationOrganizationMappingFilterInput,
} from 'api/graphql/generated/serviceTypesAndHooks';
import DefaultLayout from 'components/Layout/DefaultLayout';
import { OrganizationType } from 'components/config/types/common';
import { customerScreenConfig } from 'containers/Organizations/Customer/MainScreen/config';
import useApp from 'context/app/hooks';
import useOrganizations from 'context/organizations/hooks';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import gridApi from 'helpers/gridAPI';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import CustomerForm from '../DetailsScreen/components/Form/index';
import { customerGridOptions } from './gridOptions';

export default () => {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const { openDialog, closeDialog } = useApp();

  const {
    organizationWorkspaceData,
    flagRows,
    selectedItems,
    setSelectedItems,
    getCustomerList,
    refreshWorkspaceData,
    deleteOrganizations,
  } = useOrganizations();

  const onOrganizationUpdate = (response: Organization) => {
    closeDialog();
    const rowNode = window.$gridApi.getRowNode?.(response?.id);
    if (rowNode) {
      rowNode.setData(response);
    } else {
      gridApi.add({ data: response, gridApiRef: window.$gridApi, addIndex: 0 });
    }
  };

  function _createNewOrganization() {
    openDialog({
      content: <CustomerForm afterValidation={onOrganizationUpdate} />,
      dialogProps: {
        closable: false,
        height: '770px',
        width: '720px',
      },
    });
  }

  function _viewOrganizationRecord(record: Organization) {
    openDialog({
      content: (
        <CustomerForm place={record} afterValidation={onOrganizationUpdate} />
      ),
      dialogProps: {
        closable: false,
        height: '770px',
        width: '720px',
      },
    });
  }

  const handleDeleteCustomer = async ids => {
    const result = await deleteOrganizations(ids);

    return {
      message: result?.data.deleteOrganization
        ? 'Deleted organization(s) successfully'
        : 'Cannot delete selected organization(s)',
      data: result?.data,
    };
  };

  const gridOptions = {
    ...customerGridOptions,
    onSelectionChanged: (event: SelectionChangedEvent) => {
      const { api } = event;
      const records = [];
      const selectedRows = api.getSelectedRows();
      api.forEachNodeAfterFilter((rowNode, _index) => {
        if (selectedRows.find(row => row.id === rowNode.data.id))
          records.push(rowNode.data);
      });
      setSelectedItems([...records]);
    },
  } as GridOptions;

  useEffect(() => {
    dispatch(
      actions.setWorkspace({
        workspace: { ...customerScreenConfig, gridOptions },
        query: {
          organizationTypeId: OrganizationType.Customer,
          cursor: null,
          filters: {
            or: [{ isDeleted: { neq: true } }],
          } as OrganizationOrganizationMappingFilterInput,
          order: { id: 'DESC' },
        },
      }),
    );
  }, []);

  return (
    <DefaultLayout
      gridOptions={gridOptions}
      data={organizationWorkspaceData}
      flagRows={flagRows}
      selectedItems={selectedItems}
      onGetDataList={getCustomerList}
      onRefreshDataList={refreshWorkspaceData}
      onCreateNew={_createNewOrganization}
      onViewRecord={_viewOrganizationRecord}
      onDelete={handleDeleteCustomer}
    />
  );
};
