import { Avatar, Space, Tooltip, Typography } from 'antd';
import { selectShowRightSideMenu } from 'context/rightSideMenu/selectors';
import { setShowRightSideMenu } from 'context/rightSideMenu/slice';
import useAuthentication from 'context/security_authentication/hook';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'twin.macro';

const { Title } = Typography;

const StyledTitle = styled(Title)`
  margin-bottom: 0px !important;
  text-align: end !important;
`;

export default function RightHeader() {
  const dispatch = useDispatch();
  const { currentAccount } = useAuthentication();
  const showDrawer = useSelector(selectShowRightSideMenu);

  const renderAvatar = () => {
    const firstName = currentAccount?.contact?.firstName
      ? currentAccount?.contact?.firstName[0]
      : currentAccount?.userName[0] || 'N';
    const lastName = currentAccount?.contact?.lastName
      ? currentAccount?.contact?.lastName[0]
      : currentAccount?.userName[1] || 'A';
    const avatarName = firstName + lastName;

    return (
      <Avatar
        style={{
          backgroundColor: 'gray',
          verticalAlign: 'middle',
          cursor: 'pointer',
        }}
        onClick={() => dispatch(setShowRightSideMenu(!showDrawer))}
      >
        {avatarName?.toUpperCase()}
      </Avatar>
    );
  };

  const renderAccountSection = () => {
    const displayNameOrg =
      currentAccount?.organizationOrganizationMapping?.displayName;
    const accountName =
      currentAccount?.contact?.firstName && currentAccount?.contact?.lastName
        ? `${currentAccount?.contact?.firstName} ${currentAccount?.contact?.lastName}`
        : currentAccount?.userName || 'NA';
    return (
      <div className="flex mx-2 items-center" style={{ height: '64px' }}>
        <Space direction="vertical" className="mr-2 items-end">
          <Tooltip
            placement="top"
            title={`User ID: ${currentAccount?.id}`}
            color="gray"
          >
            <StyledTitle
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                color: '#40464F',
              }}
            >
              {accountName}
            </StyledTitle>
          </Tooltip>
          <Tooltip
            placement="top"
            title={`Subscriber ID: ${currentAccount?.organizationOrganizationMapping?.parentOrganizationId}`}
            color="gray"
          >
            <StyledTitle
              style={{
                fontWeight: 'normal',
                color: '#A6A9AC',
                fontSize: '11px',
              }}
            >
              {displayNameOrg}
            </StyledTitle>
          </Tooltip>
        </Space>
        {renderAvatar()}
      </div>
    );
  };

  return <>{renderAccountSection()}</>;
}
