import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Marker } from 'react-map-gl';

type Props = Readonly<{
  longitude: number;
  latitude: number;
  color?: string;
}>;

export default function MarkerPin({ longitude, latitude, color }: Props) {
  return (
    <Marker longitude={longitude} latitude={latitude}>
      <span>
        <FontAwesomeIcon
          icon={faLocationDot}
          style={{ fontSize: '24px', color }}
        />
      </span>
    </Marker>
  );
}
