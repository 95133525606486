import React from 'react';

function getMemoPrimaryColor({
  property,
  value,
}: {
  property: 'bg' | 'text';
  value: string;
}) {
  const colors = {
    bg: {
      urgent: 'bg-memo-urgent',
      today: 'bg-memo-today',
      tomorrow: 'bg-memo-tomorrow',
    },
    text: {
      urgent: 'text-memo-urgent',
      today: 'text-memo-today',
      tomorrow: 'text-memo-tomorrow',
    },
  };

  return colors[property][value] || colors[property]['default'];
}

export default function MemoCell({ value }: Readonly<{ value: string }>) {
  return (
    <div className="flex items-center my-1 relative cursor-pointer">
      <div
        className={`flex w-full items-center px-1 rounded-md bg-opacity-[.15] ${getMemoPrimaryColor(
          {
            property: 'bg',
            value: value.toLowerCase(),
          },
        )}`}
      >
        <span
          className={`text-sm ${getMemoPrimaryColor({
            property: 'text',
            value: value.toLowerCase(),
          })}   `}
        >
          {value}
        </span>
      </div>
    </div>
  );
}
