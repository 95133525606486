import {
  useAddFafGroupMutation,
  useAddFafGroupNewMutation,
  useAddFafGroupNewOrgOrgMappingsMutation,
  useAddFafGroupOrgMappingsMutation,
  useAddFafGroupRateMutation,
  useAddRateCommodityMutation,
  useAddRateGroupMutation,
  useAddRateGroupOrgMappingsMutation,
  useAddRateLinesMutation,
  useAddRateRateGroupMappingsMutation,
  useAddRatesMutation,
  useDeleteFafGroupMutation,
  useDeleteFafGroupNewInOrgOrgMappingsMutation,
  useDeleteFafGroupNewMutation,
  useDeleteFafGroupOrgMappingsMutation,
  useDeleteFafGroupRatesMutation,
  useDeleteRateGroupMutation,
  useDeleteRateGroupOrgMappingsMutation,
  useDeleteRateLinesMutation,
  useDeleteRatesMutation,
  useGetAvailableOrganizationsForFafGroupLazyQuery,
  useGetCustomersLazyQuery,
  useGetFafGroupNewsLazyQuery,
  useGetFafGroupRatesByFafGroupNewIdLazyQuery,
  useGetFafGroupsLazyQuery,
  useGetOrgOrgMappingsByFafGroupNewIdLazyQuery,
  useGetRateGroupOrgMappingsLazyQuery,
  useGetRateGroupsLazyQuery,
  useGetRateLinesLazyQuery,
  useGetRateRateGroupMappingsLazyQuery,
  useGetRatesLazyQuery,
  useModifyFafGroupMutation,
  useModifyFafGroupNewMutation,
  useModifyFafGroupRateMutation,
  useModifyRateGroupMutation,
  useModifyRateGroupOrgMappingsMutation,
  useModifyRateLineMutation,
  useModifyRateMutation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectAvailableFafGroupNewOrgsList,
  selectFafGroupNew,
  selectFafGroupNewList,
  selectRateGroups,
  selectRateLines,
  selectRates,
  selectSelectedMenu,
  selectSelectedRate,
  sliceKey,
} from './slice';

export default function useBookingRate() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const rateGroupList = useSelector(selectRateGroups);
  const fafGroupList = useSelector(selectFafGroupNewList);
  const rateList = useSelector(selectRates);
  const selectedRate = useSelector(selectSelectedRate);
  const rateLineList = useSelector(selectRateLines);
  const selectedMenu = useSelector(selectSelectedMenu);
  const selectedFafGroupNew = useSelector(selectFafGroupNew);
  const selectedFafGroupNewOrgsList = useSelector(
    selectAvailableFafGroupNewOrgsList,
  );

  const [
    createRateMutation,
    { loading: pendingCreateRates },
  ] = useAddRatesMutation({
    onCompleted: response => {
      let newList = [response?.addRate?.rate];
      if (rateList?.nodes?.length > 0) {
        newList = [...newList, ...(rateList?.nodes ?? [])];
      }
      dispatch(
        actions.setRates({
          selectedRow: response?.addRate?.rate,
          nodes: newList,
          loading: false,
        }),
      );
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createRate = input => {
    return createRateMutation({
      variables: {
        input,
      },
    });
  };

  const [
    createRateLineMutation,
    { loading: pendingCreateRateLines },
  ] = useAddRateLinesMutation({
    onCompleted: response => {
      let newList = response?.addRateLines?.map(rateLine => rateLine?.rateLine);
      let sortedRateLines = null;

      if (selectedRate?.id && rateLineList?.nodes) {
        newList = [...newList, ...rateLineList.nodes].filter(Boolean); // Remove undefined values
        sortedRateLines = toSorted(newList);
      }

      dispatch(
        actions.setRateLines({
          nodes: sortedRateLines || newList,
          loading: false,
        }),
      );
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const toSorted = list => {
    return list.sort((rate1, rate2) => {
      if (rate1?.breakPoint1 < rate2.breakPoint1) {
        return -1;
      }
      if (rate1?.breakPoint1 > rate2.breakPoint1) {
        return 1;
      }
      return 0;
    });
  };

  const createRateLine = input => {
    return createRateLineMutation({
      variables: {
        input,
      },
    });
  };

  const [
    copyRateLineMutation,
    { loading: pendingcopyRateLines },
  ] = useAddRateLinesMutation({
    onCompleted: response => {
      const newList = response?.addRateLines?.map(
        rateLine => rateLine?.rateLine,
      );
      dispatch(
        actions.setRateLines({
          nodes: newList,
          loading: false,
        }),
      );
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const copyRateLine = input => {
    return copyRateLineMutation({
      variables: {
        input,
      },
    });
  };

  const [
    createRateGroupMutation,
    { loading: pendingCreateRateGroup },
  ] = useAddRateGroupMutation({
    onCompleted: response => {
      if (response?.addRateGroup) {
        let newList = [response?.addRateGroup?.rateGroup];
        if (rateGroupList?.length > 0) {
          newList = [...newList, ...rateGroupList];
        }
        dispatch(
          actions.setRateGroupList({
            rateGroupList: newList,
            loading: false,
          }),
        );
      }
    },
    onError: error => {
      return error;
    },
  });

  const createRateGroup = input => {
    return createRateGroupMutation({
      variables: {
        input,
      },
    });
  };

  const [
    createFafGroupMutation,
    { loading: pendingCreateFafGroup },
  ] = useAddFafGroupMutation({
    onCompleted: response => {
      if (response?.addFafGroup) {
        let newList = [response?.addFafGroup];
        if (fafGroupList?.length > 0) {
          newList = [...newList, ...fafGroupList];
        }
        dispatch(
          actions.setFafGroupList({
            fafGroupList: newList,
            loading: false,
          }),
        );
      }
    },
    onError: error => {
      return error;
    },
  });

  const createFafGroup = input => {
    return createFafGroupMutation({
      variables: {
        input,
      },
    });
  };

  const [
    createRateGroupOrgMappingMutation,
    { loading: pendingRateGroupOrgMapping },
  ] = useAddRateGroupOrgMappingsMutation({
    onCompleted: response => {
      const rateGroupOrganizationMappings =
        response?.addRateGroupOrganizationMapping?.rateGroupOrganizationMapping;
      if (rateGroupOrganizationMappings) {
        const rateGroup = rateGroupList?.map(rateGroup => {
          if (rateGroup?.id === rateGroupOrganizationMappings[0]?.rateGroupId) {
            const temp = rateGroup?.rateGroupOrganizationMappings
              ? rateGroup?.rateGroupOrganizationMappings
              : [];
            rateGroupOrganizationMappings?.push(...temp);
            return {
              ...rateGroup,
              rateGroupOrganizationMappings,
            };
          }
          return rateGroup;
        });
        dispatch(
          actions.setRateGroupList({
            rateGroupList: rateGroup,
            loading: false,
          }),
        );
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createRateGroupOrgMapping = input => {
    return createRateGroupOrgMappingMutation({
      variables: {
        input,
      },
    });
  };

  const [
    createFafGroupOrgMappingMutation,
    { loading: pendingCreateFafGroupOrgMapping },
  ] = useAddFafGroupOrgMappingsMutation({
    onCompleted: response => {
      const fafGroupOrganizationMappings =
        response?.addFafGroupOrganizationMapping;
      if (fafGroupOrganizationMappings) {
        const fafGroup = fafGroupList?.map(fafGroup => {
          if (fafGroup?.id === fafGroupOrganizationMappings[0]?.fafGroupId) {
            const temp = fafGroup?.fafGroupOrganizationMappings
              ? fafGroup?.fafGroupOrganizationMappings
              : [];
            fafGroupOrganizationMappings?.push(...temp);
            return {
              ...fafGroup,
              fafGroupOrganizationMappings,
            };
          }
          return fafGroup;
        });
        dispatch(
          actions.setFafGroupList({
            fafGroupList: fafGroup,
            loading: false,
          }),
        );
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createFafGroupOrgMapping = input => {
    return createFafGroupOrgMappingMutation({
      variables: {
        input,
      },
    });
  };

  const [
    createRateRateGroupMappingMutation,
    { loading: pendingCreateRateRateGroupMapping },
  ] = useAddRateRateGroupMappingsMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createRateRateGroupMapping = input => {
    return createRateRateGroupMappingMutation({
      variables: {
        input,
      },
    });
  };

  const [
    createRateCommodityMutation,
    { loading: pendingRateCommodity },
  ] = useAddRateCommodityMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createRateCommodity = input => {
    return createRateCommodityMutation({
      variables: {
        input,
      },
    });
  };

  const [getRatesQuery, { loading: pendingGetRates }] = useGetRatesLazyQuery({
    onCompleted: response => {
      if (response?.ratesByOrganizationIds?.nodes) {
        dispatch(
          actions.setRates({
            nodes: response?.ratesByOrganizationIds?.nodes,
            loading: false,
          }),
        );
      }
    },
  });

  const getRates = ({ filters, cursor, order, organizationIds }) => {
    getRatesQuery({
      variables: {
        filters,
        cursor,
        order,
        organizationIds,
      },
    });
  };

  const [
    getRateRateGroupMappingQuery,
    { loading: pendingGetRateRateGroupMapping },
  ] = useGetRateRateGroupMappingsLazyQuery({
    onCompleted: response => {
      if (response?.rateRateGroupMappings?.nodes) {
        const rates = response?.rateRateGroupMappings?.nodes?.map(el => {
          return el?.rate;
        });
        dispatch(
          actions.setRates({
            nodes: rates,
            loading: false,
          }),
        );
      }
    },
  });

  const getRateRateGroupMapping = ({ filters, cursor, order }) => {
    getRateRateGroupMappingQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const [
    getRateLinesQuery,
    { loading: pendingGetRateLines },
  ] = useGetRateLinesLazyQuery({
    onCompleted: response => {
      if (response?.rateLines?.nodes) {
        dispatch(
          actions.setRateLines({
            nodes: response?.rateLines?.nodes,
            loading: false,
          }),
        );
      }
    },
  });

  const getRateLines = ({ filters, cursor, order }) => {
    getRateLinesQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const [
    getOrgQuery,
    { loading: pendingGetOrgList },
  ] = useGetCustomersLazyQuery({
    onCompleted: response => {
      const orgList =
        response?.allOrganizationOrganizationMappings?.nodes || [];
      if (orgList?.length) {
        // Array.prototype.toSorted() is supported by Node.js version 20+
        const sortedCustomerItems = [...orgList].sort((a, b) => {
          const nameA = a.displayName || a.childOrganization.name;
          const nameB = b.displayName || b.childOrganization.name;
          return nameA.localeCompare(nameB);
        });

        dispatch(
          actions.setOrgList({
            orgList: sortedCustomerItems,
            loading: false,
          }),
        );
      }
    },
  });

  const getOrgList = ({ filters, cursor, order }) => {
    getOrgQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const [
    getRateGroupQuery,
    { loading: pendingGetRateGroupList },
  ] = useGetRateGroupsLazyQuery({
    onCompleted: response => {
      if (response?.rateGroups?.nodes) {
        const rateGroupList = response?.rateGroups.nodes?.map(rateGroup => {
          const filteredRateGroupOrganizationMappings = rateGroup?.rateGroupOrganizationMappings?.filter(
            el => el?.isDeleted !== true,
          );
          return {
            ...rateGroup,
            rateGroupOrganizationMappings: filteredRateGroupOrganizationMappings,
          };
        });
        dispatch(
          actions.setRateGroupList({
            rateGroupList,
            loading: false,
          }),
        );
      }
    },
  });

  const getRateGroupList = ({ filters, cursor, order }) => {
    getRateGroupQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const [
    getRateGroupOrgMappingQuery,
    { loading: pendingGetRateGroupOrgMapping },
  ] = useGetRateGroupOrgMappingsLazyQuery({
    onCompleted: response => {
      return response;
    },
  });

  const getRateGroupOrgMapping = ({ filters, cursor, order }) => {
    getRateGroupOrgMappingQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const [
    getFafGroupQuery,
    { loading: pendingGetFafGroupList },
  ] = useGetFafGroupsLazyQuery({
    onCompleted: response => {
      const fafGroupList = response?.fafGroups?.map(fafGroup => {
        const filteredFafGroupOrganizationMappings = fafGroup?.fafGroupOrganizationMappings.filter(
          el => el?.isDeleted !== true,
        );
        return {
          ...fafGroup,
          fafGroupOrganizationMappings: filteredFafGroupOrganizationMappings,
        };
      });
      dispatch(
        actions.setFafGroupList({
          fafGroupList,
          loading: false,
        }),
      );
    },
  });

  const getFafGroupList = ({ filters, order }) => {
    getFafGroupQuery({
      variables: {
        filters,
        order,
      },
    });
  };

  const [
    modifyRateGroupMutation,
    { loading: pendingModifyRateGroup },
  ] = useModifyRateGroupMutation({
    onCompleted: response => {
      const rateGroup = response?.modifyRateGroup?.rateGroup;
      if (rateGroup) {
        const newRateGroupList = rateGroupList.map(el => {
          if (el?.id === rateGroup?.id) {
            return { ...el, name: rateGroup?.name };
          }
          return el;
        });
        dispatch(
          actions.setRateGroupList({
            rateGroupList: newRateGroupList,
            loading: false,
          }),
        );
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyRateGroup = input => {
    return modifyRateGroupMutation({
      variables: {
        input,
      },
    });
  };

  const [
    modifyFafGroupMutation,
    { loading: pendingModifyFafGroup },
  ] = useModifyFafGroupMutation({
    onCompleted: response => {
      const fafGroup = response?.modifyFafGroup;
      if (fafGroup) {
        const newFafGroupList = fafGroupList.map(el => {
          if (el?.id === fafGroup?.id) {
            return fafGroup;
          }
          return el;
        });
        dispatch(
          actions.setFafGroupList({
            fafGroupList: newFafGroupList,
            loading: false,
          }),
        );
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyFafGroup = input => {
    return modifyFafGroupMutation({
      variables: {
        input,
      },
    });
  };

  const [
    modifyRateGroupOrgMappingMutation,
    { loading: pendingModifyRateGroupOrgMapping },
  ] = useModifyRateGroupOrgMappingsMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyRateGroupOrgMapping = input => {
    return modifyRateGroupOrgMappingMutation({
      variables: {
        input,
      },
    });
  };

  const [
    modifyRateMutation,
    { loading: pendingModifyRate },
  ] = useModifyRateMutation({
    onCompleted: response => {
      if (response?.modifyRate) {
        const newList = rateList?.nodes?.filter(
          rate => rate?.id !== response?.modifyRate?.rate?.id,
        );
        dispatch(
          actions.setRates({
            nodes: newList,
            loading: false,
          }),
        );
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyRate = input => {
    return modifyRateMutation({
      variables: {
        input,
      },
    });
  };

  const [
    modifyRateLineMutation,
    { loading: pendingModifyRateLine },
  ] = useModifyRateLineMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyRateLine = input => {
    return modifyRateLineMutation({
      variables: {
        input,
      },
    });
  };

  const [
    deleteRateGroupOrgMappingMutation,
    { loading: pendingDeleteRateGroupOrgMapping },
  ] = useDeleteRateGroupOrgMappingsMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteRateGroupOrgMapping = async ids => {
    const result = await deleteRateGroupOrgMappingMutation({
      variables: { rateGroupOrgIds: ids },
    });
    if (result?.data?.deleteRateGroupOrganizationMappings) {
      const newRateGroupList = rateGroupList?.map(rateGroup => {
        const rateGroupOrganizationMappings = rateGroup?.rateGroupOrganizationMappings?.filter(
          el => !ids?.find(id => id === el?.id),
        );
        return {
          ...rateGroup,
          rateGroupOrganizationMappings,
        };
      });
      dispatch(
        actions.setRateGroupList({
          rateGroupList: newRateGroupList,
          loading: false,
        }),
      );
    }
    return result;
  };

  const [
    deleteFafGroupOrgMappingMutation,
    { loading: pendingDeleteFafGroupOrgMapping },
  ] = useDeleteFafGroupOrgMappingsMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteFafGroupOrgMapping = async ids => {
    const result = await deleteFafGroupOrgMappingMutation({
      variables: { fafGroupOrgIds: ids },
    });
    if (result?.data?.deleteFafGroupOrganizationMappings) {
      const newFafGroupList = fafGroupList?.map(fafGroup => {
        const fafGroupOrganizationMappings = fafGroup?.fafGroupOrganizationMappings?.filter(
          el => !ids?.find(id => id === el?.id),
        );
        return {
          ...fafGroup,
          fafGroupOrganizationMappings,
        };
      });
      dispatch(
        actions.setFafGroupList({
          fafGroupList: newFafGroupList,
          loading: false,
        }),
      );
    }
    return result;
  };

  const [
    deleteRateGroupMutation,
    { loading: pendingDeleteRateGroup },
  ] = useDeleteRateGroupMutation({
    onCompleted: response => {
      const newRateGroupList = rateGroupList?.filter(
        el => el.id !== response?.deleteRateGroup?.rateGroup?.id,
      );
      dispatch(
        actions.setRateGroupList({
          rateGroupList: newRateGroupList,
          loading: false,
        }),
      );
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteRateGroup = id => {
    return deleteRateGroupMutation({
      variables: { id },
    });
  };

  const [
    deleteFafGroupMutation,
    { loading: pendingDeleteFafGroup },
  ] = useDeleteFafGroupMutation({
    onCompleted: response => {
      const newFafGroupList = fafGroupList?.filter(
        el => el.id !== response?.deleteFafGroup?.id,
      );
      dispatch(
        actions.setFafGroupList({
          fafGroupList: newFafGroupList,
          loading: false,
        }),
      );
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteFafGroup = id => {
    return deleteFafGroupMutation({
      variables: { id },
    });
  };

  const [
    deleteRatesMutation,
    { loading: pendingDeleteRates },
  ] = useDeleteRatesMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteRates = ids => {
    return deleteRatesMutation({
      variables: { ids },
    });
  };

  const [
    deleteRateLinesMutation,
    { loading: pendingDeleteRateLines },
  ] = useDeleteRateLinesMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteRateLines = async ids => {
    const result = await deleteRateLinesMutation({
      variables: { ids },
    });
    if (result.data?.deleteRateLines) {
      const newList = rateLineList?.nodes?.filter(
        rateLine => !ids?.find(id => id === rateLine?.id),
      );
      dispatch(
        actions.setRateLines({
          nodes: newList,
          loading: false,
        }),
      );
    }
    return result;
  };

  // FafGroupNew - CRUD

  const [
    getFafGroupNewQuery,
    { loading: pendingGetFafGroupNewList },
  ] = useGetFafGroupNewsLazyQuery({
    onCompleted: response => {
      const fafGroupNewList = response?.fafGroupNews?.map(fafGroup => {
        const filteredFafGroupOrganizationMappings = fafGroup?.organizationOrganizationMappings.filter(
          el => el?.isDeleted !== true,
        );
        return {
          ...fafGroup,
          fafGroupOrganizationMappings: filteredFafGroupOrganizationMappings,
        };
      });
      dispatch(
        actions.setFafGroupNewList({
          fafGroupList: fafGroupNewList,
          loading: false,
        }),
      );
    },
  });

  const getFafGroupNewList = ({ filters, order }) => {
    getFafGroupNewQuery({
      variables: {
        filters,
        order,
      },
    });
  };

  const [
    createFafGroupNewMutation,
    { loading: pendingCreateFafGroupNew },
  ] = useAddFafGroupNewMutation({
    onCompleted: response => {
      if (response?.addFafGroupNew) {
        let newList = [response?.addFafGroupNew];
        if (fafGroupList?.length > 0) {
          newList = [...newList, ...fafGroupList];
        }
        dispatch(
          actions.setFafGroupNewList({
            fafGroupList: newList,
            loading: false,
          }),
        );
      }
    },
    onError: error => {
      return error;
    },
  });

  const createFafGroupNew = input => {
    return createFafGroupNewMutation({
      variables: {
        input,
      },
    });
  };

  const [
    modifyFafGroupNewMutation,
    { loading: pendingModifyFafGroupNew },
  ] = useModifyFafGroupNewMutation({
    onCompleted: response => {
      if (response?.modifyFafGroupNew) {
        const newFafGroupList = fafGroupList.map(el => {
          if (el?.id === response?.modifyFafGroupNew?.id) {
            return response?.modifyFafGroupNew;
          }
          return el;
        });

        dispatch(
          actions.setFafGroupNewList({
            fafGroupList: newFafGroupList,
            loading: false,
          }),
        );
      }
    },
    onError: error => {
      return error;
    },
  });

  const modifyFafGroupNew = input => {
    return modifyFafGroupNewMutation({
      variables: {
        input,
      },
    });
  };

  const [
    deleteFafGroupNewMutation,
    { loading: pendingFafGroupNew },
  ] = useDeleteFafGroupNewMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteFafGroupNew = id => {
    return deleteFafGroupNewMutation({
      variables: { id },
    });
  };

  // FafGroupRate - CRUD

  const [
    getFafGroupRatesByFafGroupNewIdQuery,
    { loading: pendingGetFafGroupRatesByFafGroupNewId },
  ] = useGetFafGroupRatesByFafGroupNewIdLazyQuery({
    onCompleted: response => {
      if (response?.fafGroupRatesByFafGroupNewId?.nodes) {
        dispatch(
          actions.setFafGroupRates({
            fafGroupRates: response?.fafGroupRatesByFafGroupNewId?.nodes,
          }),
        );
      }
    },
  });

  const getRatesByFafGroupNewId = ({ fafGroupNewId, filters, order }) => {
    getFafGroupRatesByFafGroupNewIdQuery({
      variables: {
        fafGroupNewId,
        filters,
        order,
      },
    });
  };

  const [
    createFafGroupRateMutation,
    { loading: pendingCreateFafGroupRate },
  ] = useAddFafGroupRateMutation({
    onCompleted: response => {
      const newRate = response?.addFafGroupRate;
      if (newRate) {
        dispatch(
          actions.setSelectedFafGroupNew({
            selectedFafGroupNew: {
              ...selectedFafGroupNew,
              fafGroupRates: [
                ...selectedFafGroupNew.fafGroupRates,
                ...[newRate],
              ],
            },
          }),
        );
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createFafGroupRate = input => {
    return createFafGroupRateMutation({
      variables: {
        input,
      },
    });
  };

  const [
    modifyFafGroupRateMutation,
    { loading: pendingModifyFafGroupRate },
  ] = useModifyFafGroupRateMutation({
    onCompleted: response => {
      const updatedRate = response?.modifyFafGroupRate;

      dispatch(
        actions.setSelectedFafGroupNew({
          selectedFafGroupNew: {
            ...selectedFafGroupNew,
            fafGroupRates: [
              ...selectedFafGroupNew.fafGroupRates,
              ...[updatedRate],
            ],
          },
        }),
      );
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyFafGroupRate = input => {
    return modifyFafGroupRateMutation({
      variables: {
        input,
      },
    });
  };

  const [
    deleteFafGroupRatesMutation,
    { loading: pendingDeleteFafGroupRates },
  ] = useDeleteFafGroupRatesMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteFafGroupRates = ids => {
    return deleteFafGroupRatesMutation({
      variables: { ids },
    });
  };

  // FafGroup Customer - CRUD

  const [
    getFafGroupNewOrgsQuery,
    { loading: pendingGetAvailableFafGroupNewOrgsList },
  ] = useGetAvailableOrganizationsForFafGroupLazyQuery({
    onCompleted: response => {
      const { nodes } = response.allOrganizationOrganizationMappingsForFafGroup;
      if (nodes) {
        dispatch(
          actions.setAvailableFafGroupNewOrgs({
            availableFafGroupNewOrgsList: nodes,
          }),
        );
      }
    },
  });

  const getAvailableFafGroupNewOrgs = ({ filters, order }) => {
    getFafGroupNewOrgsQuery({
      variables: {
        filters,
        order,
      },
    });
  };

  const [
    getOrgOrgMappingsByFafGroupNewIdLazyQuery,
    { loading: pendingGetOrgOrgMappingsByFafGroupNewId },
  ] = useGetOrgOrgMappingsByFafGroupNewIdLazyQuery({
    onCompleted: response => {
      if (response?.orgOrgMappingsByFafGroupNewId?.nodes) {
        dispatch(
          actions.setFafGroupCustomers({
            fafGroupCustomers: response?.orgOrgMappingsByFafGroupNewId?.nodes,
          }),
        );
      }
    },
  });

  const getOrgOrgMappingsByFafGroupNewId = ({
    fafGroupNewId,
    filters,
    order,
  }) => {
    getOrgOrgMappingsByFafGroupNewIdLazyQuery({
      variables: {
        fafGroupNewId,
        filters,
        order,
      },
    });
  };

  const [
    addFafGroupNewOrgOrgMappingsQuery,
    { loading: pendingAddFafGroupNewOrgOrgMappings },
  ] = useAddFafGroupNewOrgOrgMappingsMutation({
    onCompleted: response => {
      const newOrgOrgMapping =
        response?.addFafGroupNewOrgOrgMappings?.organizationOrganizationMapping;
      dispatch(
        actions.setSelectedFafGroupNew({
          selectedFafGroupNew: {
            ...selectedFafGroupNew,
            organizationOrganizationMappings: [
              ...selectedFafGroupNew.organizationOrganizationMappings,
              ...newOrgOrgMapping,
            ],
          },
        }),
      );

      return response;
    },
    onError: error => {
      return error;
    },
  });

  const addFafGroupNewOrgOrgMappings = ({
    fafGroupNewId,
    orgOrgMappingIds,
  }) => {
    return addFafGroupNewOrgOrgMappingsQuery({
      variables: {
        fafGroupNewId,
        orgOrgMappingIds,
      },
    });
  };

  const [
    deleteFafGroupNewInOrgOrgMappingsMutation,
    { loading: pendingDeleteFafGroupNewInOrgOrgMapping },
  ] = useDeleteFafGroupNewInOrgOrgMappingsMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteFafGroupNewInOrgOrgMapping = ids => {
    return deleteFafGroupNewInOrgOrgMappingsMutation({
      variables: { ids },
    });
  };

  return {
    getOrgOrgMappingsByFafGroupNewId,
    pendingGetOrgOrgMappingsByFafGroupNewId,
    getRatesByFafGroupNewId,
    pendingGetFafGroupRatesByFafGroupNewId,
    selectedMenu,
    rateLineList,
    getAvailableFafGroupNewOrgs,
    getOrgList,
    getRateGroupList,
    getFafGroupList,
    getFafGroupNewList,
    getRates,
    getRateLines,
    getRateRateGroupMapping,
    getRateGroupOrgMapping,
    createRate,
    createRateLine,
    createRateGroup,
    createFafGroup,
    createRateCommodity,
    createRateGroupOrgMapping,
    createFafGroupOrgMapping,
    createRateRateGroupMapping,
    createFafGroupRate,
    createFafGroupNew,
    copyRateLine,
    modifyFafGroupRate,
    modifyRateGroup,
    modifyFafGroup,
    modifyRateGroupOrgMapping,
    modifyRate,
    modifyRateLine,
    modifyFafGroupNew,
    deleteRateGroupOrgMapping,
    deleteFafGroupOrgMapping,
    deleteRateGroup,
    deleteFafGroup,
    deleteRates,
    deleteRateLines,
    deleteFafGroupRates,
    deleteFafGroupNew,
    deleteFafGroupNewInOrgOrgMapping,
    addFafGroupNewOrgOrgMappings,
    pendingAddFafGroupNewOrgOrgMappings,
    pendingDeleteFafGroupNewInOrgOrgMapping,
    pendingFafGroupNew,
    pendingCreateRates,
    pendingCreateRateLines,
    pendingCreateRateGroup,
    pendingCreateFafGroup,
    pendingCreateRateRateGroupMapping,
    pendingCreateFafGroupOrgMapping,
    pendingcopyRateLines,
    pendingGetOrgList,
    pendingGetRates,
    pendingGetRateLines,
    pendingGetRateGroupList,
    pendingGetFafGroupList,
    pendingGetFafGroupNewList,
    pendingGetRateRateGroupMapping,
    pendingGetRateGroupOrgMapping,
    pendingModifyRateGroup,
    pendingModifyFafGroup,
    pendingModifyRateGroupOrgMapping,
    pendingModifyRate,
    pendingModifyRateLine,
    pendingDeleteRateGroupOrgMapping,
    pendingDeleteFafGroupOrgMapping,
    pendingRateGroupOrgMapping,
    pendingRateCommodity,
    pendingDeleteRateGroup,
    pendingDeleteFafGroup,
    pendingDeleteRates,
    pendingDeleteRateLines,
    pendingCreateFafGroupRate,
    pendingModifyFafGroupRate,
    pendingDeleteFafGroupRates,
    pendingCreateFafGroupNew,
    pendingModifyFafGroupNew,
    pendingGetAvailableFafGroupNewOrgsList,
    selectedRate,
    selectedFafGroupNew,
    selectedFafGroupNewOrgsList,
  };
}
