import React, { useEffect, useRef } from 'react';
import Map, { MapRef, ViewState } from 'react-map-gl';
import MarkerPin from './MarkerPin';
import { genericConfig } from './config';
import { IMarker } from './types';

export default ({
  config = genericConfig,
  initialViewState,
  pins = [],
  zoom = 8,
}: {
  config?: any;
  initialViewState?: Partial<ViewState>;
  pins?: Array<Omit<IMarker, 'zoom'> & { id?: string }>;
  zoom?: number;
}) => {
  const mapRef = useRef<MapRef>();
  const [mapLoaded, setMapLoaded] = React.useState(false);

  const latitude = initialViewState?.latitude ?? -37.00812673231812;
  const longitude = initialViewState?.longitude ?? 174.88722111163142;

  // https://visgl.github.io/react-map-gl/docs/api-reference/map#initialviewstate
  const mapViewState: Partial<ViewState> = {
    latitude,
    longitude,
    zoom,
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  useEffect(() => {
    const initialPickupLLocation = pins[0]; // pickup location
    const initialLongitude = initialPickupLLocation?.longitude ?? longitude;
    const initialLatitude = initialPickupLLocation?.latitude ?? latitude;
    if (mapLoaded && mapRef.current) {
      mapRef.current.setCenter([initialLongitude, initialLatitude]);
      mapRef.current.setZoom(mapViewState.zoom);
    }
  }, [mapLoaded, latitude, longitude, zoom]);

  const markers = (
    <>
      {pins.map((pin, index) => (
        <MarkerPin
          key={pin.id ?? index}
          longitude={pin.longitude}
          latitude={pin.latitude}
          color={pin.color}
        />
      ))}
    </>
  );

  return (
    <Map
      ref={mapRef}
      initialViewState={mapViewState}
      {...config}
      onLoad={handleMapLoad}
    >
      {markers}
    </Map>
  );
};
