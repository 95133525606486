import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// https://github.com/jquense/yup
const schema = yup
  .object()
  .shape({
    name: yup.string().required({ message: 'Organization name is required' }),
    displayName: yup.string().required({ message: 'Display name is required' }),
    address1: yup.string().required({ message: 'Location is required' }),
    docketPrefix: yup
      .string()
      .required({ message: 'Docket prefix is required' }),
    rangeStart: yup.number().required({ message: 'Range start is required' }),
  })
  .required();
export { schema };

export default yupResolver(schema);
