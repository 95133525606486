import React from 'react';
import tw, { styled } from 'twin.macro';
import MemoCell from './MemoCell';
import ShiftCell from './ShiftCell';
import StatusCell from './StatusCell';
import { composeCellStyles, formatValue } from './styleFormatter';
import { ICustomCellRenderer } from './types';

const StyledCell = styled.div`
  ${tw`flex flex-row items-center w-full px-2`}
  height: 28px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
`;

const CellTypeFormatter = (props: ICustomCellRenderer) => {
  let { value } = props;
  const { type, precision, justify } = props;

  if (!value) {
    return null;
  }

  if (typeof value === 'object') {
    value = value.name;
  }

  if (type === 'status') {
    return <StatusCell label={value} />;
  }

  if (type === 'editable') {
    return <MemoCell value={value} />;
  }

  if (type === 'shift') {
    return <ShiftCell value={value} />;
  }

  return (
    <div className="flex w-full items-center">
      <div
        className={`truncate w-full ${composeCellStyles(type, value, justify)}`}
      >
        {type === 'currency' && <span className="text-black-200">$</span>}
        {formatValue(type, value, precision)}
      </div>
    </div>
  );
};

export default (props: ICustomCellRenderer) => (
  <StyledCell>
    <CellTypeFormatter {...props} />
  </StyledCell>
);
